import { configure } from "axios-hooks";
import LRU from "lru-cache";
import Axios from "axios";
import { esConfigureAuthStorage } from '../Util/Auth';
import { Auth } from "aws-amplify";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

const cache = new LRU({ max: 10 });

export const axiosPost = (url, data) => axios.post(url, data)
export const axiosUpdate = (url, data) => axios.patch(url, data)
export const axiosDelete = (url) => axios.delete(url)
export const axiosGet = (url, filter) => axios.get(url, filter)

const localStorageService = esConfigureAuthStorage();

export async function checkAuth() {
  localStorageService.getItem('token') ? Promise.resolve() : Promise.reject()
}

// Add a request interceptor
axios.interceptors.request.use(
  async config => {
    let token = localStorageService.getItem('userToken')
    const user = await Auth.currentAuthenticatedUser()
    if (user) {
      token = user.signInUserSession.idToken.jwtToken
      localStorageService.setItem('userToken', user.signInUserSession.idToken.jwtToken);
    }
    config.headers['Authorization'] = 'Bearer '+ token;

    return config;
  },
  error => {
    Promise.reject(error)
  });

configure({ axios, cache });
