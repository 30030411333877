import React from "react";

import Box from "@material-ui/core/Box";
import { CircularProgress } from "@material-ui/core";

import logo from "../../assets/logo.png";

const Loading = ({ size = 70 }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flex="1"
      height="100vh"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={size} />
        <Box mt={2}>
          <img src={logo} alt="logo-sanar-loading" height={40} />
        </Box>
      </Box>
    </Box>
  );
};

export default Loading;
