import React, { Component } from "react";

import {
  Box,
  Typography,
  TextareaAutosize,
  Collapse,
  Button
} from "@material-ui/core";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: "", open: false };
  }

  componentDidCatch(error, errorInfo) {
    console.log("ErrorBoundary:", error);
    this.setState({
      hasError: true,
      message: !!error.message ? error.message : error
    });

    !!this.props.onError && this.props.onError(error, errorInfo);
  }

  render() {
    const { hasError, message, open } = this.state;
    const { children, component, ...props } = this.props;

    return hasError ? (
      component ? (
        component
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          p="50px"
          height="100vh"
          {...props}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <Box mr={2}>
              <Typography variant="h4">Ocorreu um erro</Typography>
            </Box>
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                this.setState(old => ({
                  ...old,
                  open: !old.open
                }))
              }
            >
              {open ? "Menos detalhes" : "Mais detalhes"}
            </Button>
          </Box>
          <Collapse in={open} style={{ width: "100%" }}>
            <TextareaAutosize
              style={{ width: "100%", resize: "none" }}
              value={JSON.stringify(message, undefined, 4)}
              disabled
            />
          </Collapse>
        </Box>
      )
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
