import React from "react";
import ReactDOM from "react-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import { HashRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import "./index.css";
import "./service";
import App from "./App";

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: "#85c188",
        contrastText: "#fff"
      },
      secondary: {
        main: "#3b89bd"
      }
    }
  },
  ptBR
);

ReactDOM.render(
  <HashRouter>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </HashRouter>,
  document.getElementById("root")
);

