import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Auth } from 'aws-amplify'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [logged, setLogged] = useState(true)

    const verifyToken = () => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setLogged(true)
            })
            .catch(() => {
                setLogged(false)
            })
    }

    useEffect(() => {
        verifyToken()
    }, [])

    return (
        <Route
            {...rest}
            render={props =>
                logged ? <Component {...props} /> : <Redirect to='/login' />
            }
        />
    )
}

export default PrivateRoute
