import React, { Suspense } from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import { SnackbarProvider } from "notistack";
import Loading from "./components/Loading";
import ErrorBoundary from "./components/ErrorBoundary";
import PrivateRoute from "./pages/Private/PrivateRoute";
import AWSAmplifyConfig from './configs/aws-amplify'
import Amplify from "aws-amplify";


const Auth = React.lazy(() => import("./pages/Login"));
const Private = React.lazy(() => import("./pages/Private"));


Amplify.configure(AWSAmplifyConfig)

function App() {
  return (
    <SnackbarProvider>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/login" component={Auth} />
            <PrivateRoute path="/inicio" component={Private} />
            <Route path="*" render={() => <Redirect to="/inicio" />} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </SnackbarProvider>
  );
}

export default App;
